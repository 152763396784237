.loader {
  height: 5rem;
  width: 5rem;

  margin: auto;

  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;
  width: 100%;
}

.page-layout__content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  margin-top: 8rem;
  max-width: 120rem;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}

