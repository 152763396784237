@charset "UTF-8";

/* ============================================================

  Base

============================================================ */

:root {
  --font-sans-serif: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
    'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
  --font-monospace: monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f6f6f6;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #00655b;
  text-decoration: underline;
}
a:hover {
  color: #004d45;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

/* ============================================================

  Container

============================================================ */
.container,
.container-fluid {
  width: 100%;
  padding-right: var(--gutter-x, 0.75rem);
  padding-left: var(--gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 1280px;
  min-width: 1280px;
}

/* ============================================================

  Grid

============================================================ */
.row {
  --gutter-x: 1.5rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gutter-y) * -1);
  margin-right: calc(var(--gutter-x) * -0.5);
  margin-left: calc(var(--gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-top: var(--gutter-y);
}

.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.col-44p {
  flex: 0 0 auto;
  width: 44%;
}
.col-40p {
  flex: 0 0 auto;
  width: 40%;
}
.col-36p {
  flex: 0 0 auto;
  width: 36%;
}
.col-30p {
  flex: 0 0 auto;
  width: 30%;
}
.col-32p {
  flex: 0 0 auto;
  width: 32%;
}
.col-28p {
  flex: 0 0 auto;
  width: 28%;
}

/* ============================================================

  Utility

============================================================ */

/* ------------------------------
  overflow
------------------------------ */
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}

/* ------------------------------
  text align
------------------------------ */
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

/* ------------------------------
  text decoration
------------------------------ */
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* ------------------------------
  text wrap
------------------------------ */
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}

/* ------------------------------
  font weight
------------------------------ */
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}

/* ------------------------------
  font size
------------------------------ */
.fs-xxlarge {
  font-size: xx-large !important;
}
.fs-xlarge {
  font-size: x-large !important;
}
.fs-large {
  font-size: large !important;
}
.fs-medium {
  font-size: medium !important;
}
.fs-small {
  font-size: small !important;
}
.fs-xsmall {
  font-size: x-small !important;
}
.fs-xxsmall {
  font-size: xx-small !important;
}

/* ------------------------------
  text color
------------------------------ */
.text-transparent {
  color: transparent !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #00655b !important;
}
.text-danger {
  color: #f00 !important;
}
.text-gray {
  color: #cccccc !important;
}
.text-ashgray {
  color: #9fa09e !important;
}
.text-darkgray {
  color: #747474 !important;
}
.text-lightgray {
  color: #f2f2f2 !important;
}
.text-lightyellow {
  color: #fcf7e4 !important;
}
.text-skyblue {
  color: #e4f7f5 !important;
}
.text-orange {
  color: #ff7800 !important;
}
.text-darkorange {
  color: #d14509 !important;
}

/* ------------------------------
  line height
------------------------------ */
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}

/* ------------------------------
  background
------------------------------ */
.bg-transparent {
  background-color: transparent !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-primary {
  background-color: #00655b !important;
}
.bg-danger {
  background-color: #f00 !important;
}
.bg-gray {
  background-color: #cccccc !important;
}
.bg-ashgray {
  background-color: #9fa09e !important;
}
.bg-darkgray {
  background-color: #747474 !important;
}
.bg-lightgray {
  background-color: #f2f2f2 !important;
}
.bg-lightyellow {
  background-color: #fcf7e4 !important;
}
.bg-skyblue {
  background-color: #e4f7f5 !important;
}
.bg-orange {
  background-color: #ff7800 !important;
}
.bg-darkorange {
  background-color: #d14509 !important;
}

/* ------------------------------
  display
------------------------------ */
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}

/* ------------------------------
  border
------------------------------ */
.border {
  border: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: 1px solid #dee2e6 !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: 1px solid #dee2e6 !important;
}
.border-start-0 {
  border-left: 0 !important;
}

/* ------------------------------
  border color
------------------------------ */
.border-transparent {
  border-color: transparent !important;
}
.border-white {
  border-color: #fff !important;
}
.border-primary {
  border-color: #00655b !important;
}
.border-danger {
  border-color: #f00 !important;
}
.border-gray {
  border-color: #cccccc !important;
}
.border-ashgray {
  border-color: #9fa09e !important;
}
.border-darkgray {
  border-color: #747474 !important;
}
.border-lightgray {
  border-color: #f2f2f2 !important;
}
.border-lightyellow {
  border-color: #fcf7e4 !important;
}
.border-skyblue {
  border-color: #e4f7f5 !important;
}
.border-orange {
  border-color: #ff7800 !important;
}
.border-darkorange {
  border-color: #d14509 !important;
}

/* ------------------------------
  border style
------------------------------ */
.bs-dashed {
  border-style: dashed !important;
}
.bs-top-dashed {
  border-top-style: dashed !important;
}
.bs-bottom-dashed {
  border-bottom-style: dashed !important;
}
.bs-start-dashed {
  border-left-style: dashed !important;
}
.bs-end-dashed {
  border-right-style: dashed !important;
}

/* ------------------------------
  border width
------------------------------ */
.bw-1 {
  border-width: 1px !important;
}
.bw-top-1 {
  border-top-width: 1px !important;
}
.bw-bottom-1 {
  border-bottom-width: 1px !important;
}
.bw-start-1 {
  border-left-width: 1px !important;
}
.bw-end-1 {
  border-right-width: 1px !important;
}
.bw-2 {
  border-width: 2px !important;
}
.bw-top-2 {
  border-top-width: 2px !important;
}
.bw-bottom-2 {
  border-bottom-width: 2px !important;
}
.bw-start-2 {
  border-left-width: 2px !important;
}
.bw-end-2 {
  border-right-width: 2px !important;
}

/* ------------------------------
  border radius
------------------------------ */
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 0.2rem !important;
}
.rounded-2 {
  border-radius: 0.25rem !important;
}
.rounded-3 {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

/* ------------------------------
  width height
------------------------------ */
.w-0 {
  width: 0 !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mw-none {
  max-width: none !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}

.h-0 {
  height: 0 !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.mh-none {
  max-height: none !important;
}
.mh-500px {
  max-height: 500px !important;
}
.mh-600px {
  max-height: 600px !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-h-380 {
  min-height: 380px !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}

/* ------------------------------
  Margin 
------------------------------ */
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}

/* ------------------------------
  Padding 
------------------------------ */
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}

/* ------------------------------
  flex
------------------------------ */
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}

/* ============================================================

  Form

============================================================ */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type='file'] {
  overflow: hidden;
}
.form-control[type='file']:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #00655b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #00655b;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%20150%20150%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3Aserif%3D%22http%3A%2F%2Fwww.serif.com%2F%22%20style%3D%22fill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bstroke-linejoin%3Around%3Bstroke-miterlimit%3A2%3B%22%3E%20%3Cg%20transform%3D%22matrix(-1.8359%2C-2.24833e-16%2C1.99969e-16%2C-1.63287%2C234.094%2C196.537)%22%3E%20%3Cpath%20d%3D%22M86.657%2C52.89L124.544%2C92.299L48.771%2C92.299L86.657%2C52.89Z%22%20style%3D%22fill%3Argb(0%2C101%2C91)%3B%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #005149;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.form-select[multiple],
.form-select[size]:not([size='1']) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-select::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
  height: 8px;
  border-top: 8px solid #00655b;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  position: relative;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  position: relative;
  z-index: 1;
  margin-left: -1em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type='checkbox'] {
  border-radius: 0;
}
.form-check-input[type='radio'] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #00655b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(38, 124, 116, 0.5);
}
.form-check-input:checked {
  background-color: #00655b;
  border-color: #00655b;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type='checkbox']:indeterminate {
  background-color: #00655b;
  border-color: #00655b;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300655b'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.form-check-label {
  margin-left: -1.5em;
  padding-left: 2em;
  padding-right: 0.5em;
  border-radius: 0.125rem;
  overflow-wrap: break-word;
}
.form-check-input:checked + .form-check-label {
  background-color: #d2edea;
}

/* ============================================================

  combobox

============================================================ */
.combobox {
  position: relative;
  width: 100%;
  min-width: 200px;
}

.combobox-title {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #00655b;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: default;
  overflow-wrap: break-word;
}
.combobox.active,
.combobox.active .combobox-dropdpwn {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.combobox.active .combobox-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.combobox-dropdpwn {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.combobox-list {
  overflow-y: auto;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  border-top: 1px solid #ced4da;
}
.combobox-list .form-check {
  margin-bottom: 0;
}
.combobox-list .form-check-label {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.combobox-list .form-check-input {
  margin-top: 0.5em;
}
.combobox-list .form-check:hover {
  background-color: #e5f7f5;
}
.combobox:not(.active) .combobox-dropdpwn {
  display: none;
}
.combobox-search {
  margin: 0.5rem;
}
.combobox-search + .combobox-list {
  border-top: 1px solid #ced4da;
}
.combobox-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 0.5rem 0.5rem;
  gap: 0.5rem;
}
.combobox::after {
  content: '';
  background: #00655b;
  width: 11px;
  height: 7px;
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translate(0, -50%);
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.combobox .form-check {
  font-size: 0.875em;
}
.combobox .form-control {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.combobox-sm .combobox-title {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

/* ============================================================

  Button

============================================================ */
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

/* ============================================================

  Button Color

============================================================ */
.btn-primary {
  color: #fff;
  background-color: #00655b;
  border-color: #00655b;
  border-width: 2px;
}
.btn-primary:hover {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
}
.btn-primary:focus {
  color: #fff;
  background-color: #00655b;
  border-color: #00655b;
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.btn-primary:active,
.btn-primary.active {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
}
.btn-primary:active:focus,
.btn-primary.active:focus {
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.btn-primary:disabled,
.btn-primary.disabled {
  color: #888888;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.btn-outline-primary {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
  border-width: 2px;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #00655b;
  border-color: #00655b;
}
.btn-outline-primary:focus {
  color: #fff;
  background-color: #00655b;
  border-color: #00655b;
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.btn-outline-primary:active,
.btn-outline-primary.active {
  color: #fff;
  background-color: #00655b;
  border-color: #00655b;
}
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus {
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #888888;
  background-color: #ebebeb;
  border-color: #888888;
}

.btn-warning {
  color: #000;
  background-color: #fbba42;
  border-color: #fbba42;
}
.btn-warning:hover {
  color: #000;
  background-color: #fcc45e;
  border-color: #fbc155;
}
.btn-warning:focus {
  color: #000;
  background-color: #fcc45e;
  border-color: #fbc155;
  box-shadow: 0 0 0 0.25rem rgba(213, 158, 56, 0.5);
}
.btn-warning:active,
.btn-warning.active {
  color: #000;
  background-color: #fcc868;
  border-color: #fbc155;
}
.btn-warning:active:focus,
.btn-warning.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(213, 158, 56, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
  color: #888888;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

/* ============================================================

  Page

============================================================ */
#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* ============================================================

  Main

============================================================ */
.main {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  flex-grow: 1;
  z-index: 1;
}

/* ============================================================

  Header

============================================================ */
.header {
  border-top: 3px solid #00655b;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header.fixed {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
}

.header-left {
  text-align: left;
  height: 55px;
  display: flex;
}

.header-center {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  height: 57px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.header-right {
  text-align: right;
}

.title {
  white-space: nowrap;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}

.btn-menu {
  padding: 0;
  width: 40px;
  height: 40px;
  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  border-radius: 50%;
  background-color: #d2edea;
  border-color: #d2edea;
  border-width: 2px;
}
.btn-menu:hover {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
}
.btn-menu:focus {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.btn-menu:active,
.btn-menu.active {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
}
.btn-menu:active:focus,
.btn-menu.active:focus {
  box-shadow: 0 0 0 0.25rem #c5c5c5;
}
.btn-menu:disabled,
.btn-menu.disabled {
  color: #888888;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.btn-menu-logout {
  background-image: url(../public/img/icon_logout.png);
}
.btn-menu-back {
  background-image: url(../public/img/icon_back.png);
}

.brand {
  text-decoration: none;
  color: #00655b;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
  font-size: 1rem;
  width: fit-content;
}

.brand:hover {
  text-decoration: none;
  color: #00655b;
}

.brand .logo {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.brand .name {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.25rem;
}

.brand span {
  white-space: nowrap;
}

.expiration span {
  display: inline-block;
  color: #b9605b;
  background-color: #fff;
  border: 2px solid;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  border-radius: 0.25rem;
  border-color: #b9605b;
  text-align: center;
}

/* ============================================================

  Footer

============================================================ */
.footer {
  padding: 0.5rem 0;
  background-color: #f6f6f6;
  z-index: 10;
}

.footer-link-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: calc(1.25rem + 1px);
}

.footer-link-group a {
  font-size: 0.75rem;
  color: #212529;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
}

.footer-link-group a:hover {
  text-decoration: underline;
}

.footer-link-group span {
  position: relative;
  line-height: 1;
}

.footer-link-group span + span::before {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: currentColor;
  left: calc(-1px - 1.25rem / 2);
}

.copyright {
  font-size: 0.625rem;
  margin-left: auto;
}

/* ============================================================

  Page Map

============================================================ */
div#page-map {
  height: 100vh;
}

div#page-map > .main {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

/* ============================================================

  Map

============================================================ */
#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
}

.map-container {
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
}

/* ============================================================

  Table

============================================================ */
.table {
  width: 100%;
  color: #212529;
  vertical-align: middle;
  border-color: #dee2e6;
}

.table th {
  background-color: #ececec;
  font-weight: bold;
  border: 1px solid #dee2e6;
  padding: 0.5rem 0.25rem;
}

.table td {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 0.5rem 0.25rem;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #f6f6f6;
}

.table-body-striped > tbody:nth-of-type(even) > tr > * {
  background-color: #f6f6f6;
}

.table-sm td,
.table-sm th {
  font-size: 0.75rem;
  padding: 0.25rem 0.25rem;
  line-height: 1.25;
}

/* ============================================================

  Table style2

============================================================ */
.table-style2 th,
.table-style2 td {
  border-width: 2px;
  border-color: #747474;
}

.table-style2.table-striped > tbody > tr:nth-of-type(even) > *,
.table-style2.table-body-striped > tbody:nth-of-type(even) > tr > * {
  background-color: #d9d9d9;
}

/* ============================================================

  Table Scroll

============================================================ */
.table-scroll {
  max-height: 300px;
  overflow-y: scroll;
  border-left: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.table-scroll .table {
  border-collapse: separate;
  border-spacing: 0;
}
.table-scroll .table thead {
  position: sticky;
  top: 0;
}
.table-scroll .table td,
.table-scroll .table th {
  border-top-width: 0;
  border-left-width: 0;
}

/* ============================================================

  Table Select

============================================================ */
.table-select tr.selected > * {
  background-color: #e5f7f5 !important;
}

/* ============================================================

  Table Fixed

============================================================ */
tr.fixed > * {
  background-color: dimgray !important;
}

/* ============================================================

  Card

============================================================ */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.card-header {
  padding: 0.5rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-body {
  padding: 0.5rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-premium::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  border-radius: 0.25rem;
}

.card-premium input,
.card-premium label {
  pointer-events: none;
}

/* ============================================================

  Panel

============================================================ */
.panel {
  position: absolute;
  transition: transform 0.15s linear;
  z-index: 5;
}

.panel-top-left {
  top: 0;
  left: 0;
}
.panel-top-right {
  top: 0;
  right: 0;
}
.panel-center-left {
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.panel-center-right {
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.panel-bottom-left {
  left: 0;
  bottom: 0;
}
.panel-bottom-right {
  right: 0;
  bottom: 0;
}

.btn-panel-toggle {
  background-color: #fff;
  position: absolute;
  width: 30px;
  height: 32px;
  top: 1rem;
  right: -30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #dee2e6;
  border-left-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-panel-toggle:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid #00655b;
  border-top: 2px solid #00655b;
  transform: rotate(-45deg);
}

.btn-panel-toggle:focus {
  border-color: #00655b;
  box-shadow: 0 0 0 0.25rem rgba(38, 124, 116, 0.5);
}

.panel-top-right .btn-panel-toggle,
.panel-center-right .btn-panel-toggle,
.panel-bottom-right .btn-panel-toggle {
  right: auto;
  left: -30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left-width: 1px;
  border-right-width: 0;
}

.panel-top-right .btn-panel-toggle:after,
.panel-center-right .btn-panel-toggle:after,
.panel-bottom-right .btn-panel-toggle:after {
  transform: rotate(135deg);
}

.panel.hide {
  transform: translate(-100%, 0);
}
.panel-center-left.hide {
  transform: translate(-100%, -50%);
}

.panel-top-right.hide,
.panel-bottom-right.hide {
  transform: translate(100%, 0);
}

.panel-center-right.hide {
  transform: translate(100%, -50%);
}

.panel.hide .btn-panel-toggle:after {
  transform: rotate(135deg);
}

.panel-top-right.hide .btn-panel-toggle:after,
.panel-center-right.hide .btn-panel-toggle:after,
.panel-bottom-right.hide .btn-panel-toggle:after {
  transform: rotate(-45deg);
}

/* ============================================================

  Panel Map

============================================================ */
.panel-map {
  padding: 0.75rem;
  top: 0.5rem;
  width: 330px;
  background-color: #00655b;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  z-index: 7;
  max-height: calc(100% - 4rem);
}

.panel-map-body {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: auto;
}

.panel-map .btn-premium {
  z-index: 2;
  bottom: 0.5rem;
  /* right: 0.5rem; */
  position: absolute;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, 0);
}

/* ============================================================

  Panel Lang

============================================================ */
.panel-lang {
  z-index: 8;
}

.panel-lang-hazard-map {
  bottom: 22.125rem !important;
  right: 4rem !important;
}

.panel-lang-purchase-location {
  bottom: 6.875rem !important;
}

/* ============================================================

  Panel Legend

============================================================ */
.panel-legend {
  background-color: #00655b;
  padding: 0.75rem;
  bottom: 4rem;
  gap: 0.75rem;
  display: flex;
  flex-direction: row;
}

.legend-flood-depth {
  flex-direction: column;
  display: flex;
  gap: 0;
  height: calc(18px * 11);
  justify-content: space-between;
  position: relative;
}

.legend-flood-depth::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: calc(18px * 11);
  margin-bottom: 0.25rem;
  background-image: linear-gradient(0deg, #c9d7f4, #0044cd);
}
.legend-flood-depth span {
  position: relative;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 0.625rem;
}

.legend-flood-depth span::before {
  content: '';
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  background-color: transparent;
  margin-right: 0.25rem;
}

.legend-frequency {
  flex-direction: column;
  display: flex;
  gap: 0;
}

.legend-frequency span {
  position: relative;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 0.625rem;
}

.legend-frequency span::before {
  content: '';
  width: 18px;
  height: 18px;
  position: relative;
  display: inline-block;
  background-color: #ccc;
  margin-right: 0.25rem;
}

.legend-frequency span:nth-child(1):before {
  background-color: #e60a13;
}
.legend-frequency span:nth-child(2):before {
  background-color: #ec7396;
}
.legend-frequency span:nth-child(3):before {
  background-color: #eb6301;
}
.legend-frequency span:nth-child(4):before {
  background-color: #fcd200;
}
.legend-frequency span:nth-child(5):before {
  background-color: #f9f388;
}
.legend-frequency span:nth-child(6):before {
  background-color: #c2c3c3;
}
.legend-frequency span:nth-child(7):before {
  background-color: #b1dee5;
}
.legend-frequency span:nth-child(8):before {
  background-color: #59c3dc;
}
.legend-frequency span:nth-child(9):before {
  background-color: #3691cf;
}
.legend-frequency span:nth-child(10):before {
  background-color: #3055a5;
}
.legend-frequency span:nth-child(11):before {
  background-color: #0d1453;
}

/* ============================================================

  Panel Analysis ID

============================================================ */
.panel-analysis-id {
  top: 10px;
  right: 260px;
  width: 240px;
  z-index: 6;
}

@media screen and (min-width: 640px) {
  .panel-analysis-id {
    top: 10px;
  }
}

.panel-analysis-id .combobox {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

/* ============================================================

  Badge Plan

============================================================ */
.badge-plan {
  display: inline-block;
  padding: 0.35em 0.35em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  min-width: 75px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #00655b;
  background-color: #d2edea;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}

/* ============================================================

  Icon

============================================================ */
.icon {
  position: relative;
  display: inline-block;
  width: 25px;
  height: calc(1em * 1.5);
  vertical-align: -0.375em;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.icon::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  left: calc((100% - 25px) / 2);
  top: calc((100% - 25px) / 2);
  position: absolute;
  filter: brightness(0) invert(1);
  transition: filter 0.15s linear;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-cart::before {
  background-image: url(../public/img/icon_cart.png);
}
.icon-search::before {
  background-image: url(../public/img/icon_search.png);
}
.icon-back::before {
  background-image: url(../public/img/icon_back.png);
}
.icon-upload::before {
  background-image: url(../public/img/icon_upload.png);
}
.icon-pdf::before {
  background-image: url(../public/img/icon_pdf.png);
}
.icon-csv::before {
  background-image: url(../public/img/icon_csv.png);
}
.icon-analysis::before {
  background-image: url(../public/img/icon_analysis.png);
}

.btn-primary:hover .icon::before {
  filter: none;
}
.btn-primary:focus .icon::before {
  filter: brightness(0) invert(1);
}
.btn-primary:active .icon::before {
  filter: none;
}
.btn-primary:disabled .icon::before,
.btn-primary.disabled .icon::before {
  filter: brightness(0) invert(0.5);
}
.btn-outline-primary .icon::before {
  filter: none;
}
.btn-outline-primary:hover .icon::before {
  filter: brightness(0) invert(1);
}
.btn-outline-primary:focus .icon::before {
  filter: brightness(0) invert(1);
}
.btn-outline-primary:active .icon::before {
  filter: brightness(0) invert(1);
}
.btn-outline-primary:disabled .icon::before,
.btn-outline-primary.disabled .icon::before {
  filter: brightness(0) invert(0.5);
}
.btn-menu .icon::before {
  filter: none;
}
.btn-menu:hover .icon::before {
  filter: none;
}
.btn-menu:focus .icon::before {
  filter: none;
}
.btn-menu:active .icon::before {
  filter: none;
}
.btn-menu:disabled .icon::before,
.btn-menu.disabled .icon::before {
  filter: brightness(0) invert(0.5);
}

/* ============================================================

  Sticky Buttons

============================================================ */
.sticky-buttons {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  position: sticky;
  bottom: 0;
  z-index: 10;
  gap: 1rem;
}

.sticky-buttons .delete-location {
  margin-right: auto;
  color: #00655b;
  background-color: #D2EDEA;
  border-color: #00655b;
  border-width: 2px;
}

.sticky-buttons .delete-location:hover {
  color: #00655b;
  background-color: #fff;
  border-color: #00655b;
}

.sticky-buttons .delete-location:disabled {
  color: #888888;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.sticky-buttons-back {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  display: none;
}
.sticky-buttons.fixed .sticky-buttons-back {
  display: block;
  left: calc(0px - (100vw - 100%) / 2);
  background-color: rgba(0, 101, 91, 0.2);
  z-index: -1;
}

@media (max-width: 1279.98px) {
  div:not(#page-map) > header {
    width: fit-content;
  }
  div:not(#page-map) > footer {
    width: fit-content;
  }
  .sticky-buttons.fixed .sticky-buttons-back {
    width: 1280px !important;
    left: -0.75rem !important;
  }
}

/* ============================================================

  alert

============================================================ */
.alert {
  position: relative;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border: 2px solid #c9261b;
  border-radius: 0.25rem;
  color: #c9261b;
  background-color: #f7e4e1;
  font-size: 0.875rem;
}

.alert ul {
  margin-bottom: 0;
}

/* ============================================================

  Preview

============================================================ */
.preview {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  color: #ccc;
}

/* ============================================================

  Required

============================================================ */
.required {
  line-height: 1;
  font-size: 0.625rem;
  position: relative;
  padding-left: 0.625rem;
  color: #d14509;
}
.required sup {
  position: absolute;
  left: 0;
  top: 0;
}

/* ============================================================
  Infowindow
============================================================ */
.custom-infowindow {
  max-height: 250px;
  overflow-y: auto;
}
.custom-infowindow-item {
  margin-bottom: 1rem;
}
.custom-infowindow-item:last-child {
  margin-bottom: 0;
}

/* ============================================================
  Modal
============================================================ */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  flex-direction: column;
}

.progress-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  flex-direction: column;
}

.modal {
  z-index: 999999;
  width: auto;
  padding: 1em;
  background: #fff;
}

.modal-transparent {
  z-index: 999999;
  width: auto;
  padding: 1em;
}

.modal-content {
  width: 100%;
}

.modal-footer {
  width: 100%;
  text-align: center;
}

/* ============================================================
  Indicator
============================================================ */
.indicator {
  cursor: wait;
}

/* ============================================================
  ProgressBar
============================================================ */
.progress-bar {
  cursor: wait;
}

/* ============================================================
  Analysis Name Input Dialog
============================================================ */
#analysis_name_input {
  width: 350px;
}

/* ============================================================
  ScenarioSelector
============================================================ */
.location-filter-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import './views/PageLoader/page-loader.css';
